import { For, Show, Suspense } from "solid-js";
import Body from "~/components/Body";
import StoryThumbnail from "~/components/StoryThumbnail";
import Banner from "~/components/Banner";
import { Title } from "@solidjs/meta";
import { A, createAsync, RouteDefinition } from "@solidjs/router";
import { MetaDetails } from "~/components/utility";
import { getBuilderPressEntries } from "~/services/builder";

export type PressModel = {
  name: string;
  data: {
    slug: string;
    banner: string;
    bannerAlt: string;
    description: string;
    seoKeywords: string;
    body: string;
    publishAt: string;
    publishedDate: number;
    tags: string[];
    featured: boolean;
  };
  related: PressModel[];
};

export const route = {
  preload: () => getBuilderPressEntries(),
} satisfies RouteDefinition;

export default function PressLanding() {
  const content = createAsync(() => getBuilderPressEntries());
  const featured = () => {
    if (!content()) return;
    return content()?.find((item) => item.data?.featured);
  };

  const formatDate = (date: string | number) => {
    return new Date(date)
      .toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      })
      .replace(",", "");
  };

  return (
    <>
      <Suspense>
        <Title>RomaMoulding.com - Press</Title>
        <MetaDetails
          title="Press & Blogs"
          description="Read the latest about Roma!"
          keywords="blog, press, article"
        />
        <Body>
          <div class="flex flex-col gap-y-8 pb-16 pt-5">
            <Show when={featured()} keyed>
              {(feature) => (
                <A href={`/press/${feature?.data?.slug}`}>
                  <Banner
                    image={feature?.data?.banner}
                    layout={"Two-Thirds"}
                    headerType="Text"
                    header={feature.name}
                    subHeader={`${feature?.data?.tags[0]} ∙ ${formatDate(
                      feature?.data?.publishedDate
                    )}`}
                  />
                </A>
              )}
            </Show>
            <section class="flex flex-col gap-8 items-center pb-16">
              <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                <For each={content()}>
                  {(item) => (
                    <StoryThumbnail
                      title={item.name}
                      href={`/press/${item?.data?.slug}`}
                      image={item?.data?.banner}
                      bannerAlt={""}
                      description={item?.data?.description || ""}
                      category={item.data?.tags?.[0]}
                      date={new Date(item?.data?.publishedDate)}
                    />
                  )}
                </For>
              </div>
            </section>
          </div>
        </Body>
      </Suspense>
    </>
  );
}
